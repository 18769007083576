import React, { Component } from 'react'
// import { withNamespaces } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons'
import './style.scss'
function Contact(props) {
  const office = props.office;
  return (
  <div className="col-md-12 col-lg-4">
          <div className="d-flex flex-column border-styled">
            <div className="p-2 mt-4">
              <h3 className="media-title title-center">
                {/* {t('contact-headquarter')} */}
                {office.office}
              </h3>
            </div>

            <div className="p-2 media">
              <div className="icon-styled">
                <FontAwesomeIcon icon={faMapMarkedAlt} />
              </div>

              <div className="media-body">
                <p className="media-title">
                Địa chỉ: {office.address}
                </p>
              </div>
            </div>

            <div className="p-2 media">
              <div className="icon-styled">
                <FontAwesomeIcon icon={faPhone} />
              </div>

              <div className="media-body">
                <p className="media-title">
                
                  Điện thoại: {office.phone}
                </p>
                <p className="media-title">
                  {/* {t('contact-fax')} */}
                  Fax: {office.fax}
                </p>
                <p className="media-title">
                  {/* {t('contact-hotline')} */}
                  Hotline: {office.hotline}
                </p>
              </div>
            </div>
          </div>
        </div>
  )
}
class SectionContact extends Component {
  render() {
    const offices = this.props.home.offices;
    const slides = offices.length == 4 ? 
      <div className="row mt-5">
        <div className="row container">
        <div className="col-lg-4"> </div>
        <Contact office={offices[0]}/>
        <div className="col-lg-4"> </div>
        </div>
      <div className="row container">
        <Contact office={offices[1]}/>
        <Contact office={offices[2]}/>
        <Contact office={offices[3]}/>

      </div>
      </div>
      : 
      <div className="row mt-5">
        <div className="row container">
        {offices.length >0 ? offices.map((item) => <Contact office={item} key={item.id}/>) : "" }
        </div>
      </div>
    return (
      <div className="jumbotron container-fluid bg-light rounded-0 mb-0 contact-styled">
        <h1 className="display-4 text-center">
          {this.props.home.contactTitle}
          {/* Liên hệ */}
        </h1>

        <p className="lead text-center">
          {this.props.home.contactSubtitle}
          {/* There is a lot of exciting stuff going on in the stars above us that
          makes */}
        </p>

        <hr className="line bg-secondary" />
        {/* <div className="container-fluid"> */}
        {slides}
        {/* </div> */}
      </div>
    )
  }
}

// export default withNamespaces('translation')(SectionContact)
export default SectionContact
