import { Link } from 'gatsby';
import React from 'react';
import CustomLink from 'components/CustomLink';
import './style.scss';

const PRArea = ({ linkPRURL }) => (
  <div className="jumbotron bg-light jumbotron-fluid my-0 py-0">
            <div className="container-fluid">
              <div className="row justify-content-end">
                <CustomLink to={linkPRURL}>
                  <img
                    className="float-right align-middle col-3 col-md-2 col-lg-2 align-self-end"
                    src="/logos/youtube-logo.png"
                  />
                </CustomLink>
              </div>
            </div>
          </div>
);

export default PRArea;
