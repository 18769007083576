import React, { Component } from 'react'
// import { withNamespaces } from 'react-i18next'
import { Link } from 'gatsby'
import './style.scss'

class SectionPricing extends Component {
  render() {
    // const { t } = this.props
    // const home = this.props.home;
    const pricingTitle = this.props.pricingTitle;
    const pricingBtn = this.props.pricingBtn;
    return (
      <div className="mb-0 bg-dark text-light hn-bg-pricing" id="pricing">
        <div className="jumbotron rounded-0 container-fluid pricing_cover mb-0">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <h3 className="display-4 ">
                  {/* {t('pricing-title')} */}
                  {pricingTitle}
                </h3>

                <p className="lead">
                  Hotline: 0903.531.777 <br /> Email: minhnguyet@hainammedia.com
              </p>

                {/* <hr className="line bg-secondary" /> */}
              </div>

              <div className="col-sm-12 col-md-6">
                <div className="mt-4">
                  <Link className="btn btn-secondary btn-lg" to="/pricing">
                    {/* {t('pricing-btn')} */}
                    {pricingBtn}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
}

// export default withNamespaces('translation')(SectionPricing)
export default SectionPricing
